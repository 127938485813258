/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const HoldingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site: markdownRemark(
        frontmatter: { templateKey: { eq: "site-options" } }
      ) {
        id
        frontmatter {
          name
          headshot {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section
      sx={{
        bg: "primary",
        height: "100vh",
        margin: "0 auto",
        textAlign: "center",
        color: "white",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: "0 0 auto",
          padding: "2em",
          pt: "25vh",
        }}
      >
        <div
          className="navHeadshot"
          sx={{
            px: 3,
            pb: 3,
            "& img": {
              border: "1px solid white",
            },
            "& .gatsby-image-wrapper": {
              width: "125px",
            },
          }}
        >
          <Img
            fluid={data.site.frontmatter.headshot.childImageSharp.fluid}
            alt="Portia Booroff Headshot"
          />
        </div>
        <div>
          <h1
            sx={{
              fontSize: [3, 6],
              lineHeight: 1,
              marginLeft: 3,
              marginTop: 3,
              marginBottom: 2,
              textAlign: "left",
              display: "inline-block",
              position: "relative",
              maxWidth: ["100px", "200px"],
              "::before": {
                content: '""',
                display: "block",
                position: "absolute",
                width: ["4px", "8px"],
                height: ["45%", "75%"],
                left: "-16px",
                top: ["27.5%", "12.5%"], //half of what is left from height
                backgroundColor: "white",
              },
            }}
          >
            {data.site.frontmatter.name}
          </h1>
        </div>
      </Flex>
      <div
        sx={{
          fontSize: 4,
          "& a": {
            color: "white",
          },
        }}
      >
        <p>New Website Coming Soon!</p>
        <p sx={{ fontSize: 3 }}>
          Portia Booroff is a UK Actor represented by Lee Morgan Management -{" "}
          <a href="mailto:lee@leemorgan.biz">lee@leemorgan.biz</a>
        </p>
      </div>
    </section>
  )
}

export default HoldingPage
